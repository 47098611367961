/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Fragment } from "react"
import { graphql } from "gatsby"
import get from "lodash.get"

import SEO from "src/components/seo"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import Header from "src/components/Header"
import HeroRenderer from "src/components/HeroRenderer"
import TableOfContents from "src/components/TableOfContents"
import BodyContentRenderer from "src/slices/BodyContentRenderer"
import RichContentRenderer from "src/slices/RichContentRenderer"
import { EnquiryForm } from "src/components/Forms"
import Footer from "src/components/Footer"

const Page = (props) => {
  const page = get(props, "data.wpgraphql.page", {})
  const heroColor = "red"
  const headerTheme = "light"

  return (
    <Fragment>
      <SEO
        title={page.acfPageHeader.seoTitle || page.title}
        description={page.acfPageHeader.seoDescription}
        image={page.acfPageHeader.seoImage}
      />
      <SkipNavLink
        sx={{
          variant: "link.skipNav",
        }}
      />
      <Header
        defaultTheme={headerTheme}
        layout={page.acfPageHeader.layout}
        themeColor={heroColor}
      />
      <main
        sx={{
          isolation: "isolate",
        }}
      >
        <SkipNavContent />
        <HeroRenderer
          title={page.title}
          acfPageHeader={page.acfPageHeader}
          breadcrumbs={page.appBreadcrumb}
          themeColor={heroColor}
        />
        {page.acfTableOfContents.tableOfContents && (
          <TableOfContents
            acfFeatureModules={page.acfFeatureModules}
            acfBodyContent={page.acfBodyContent}
            acfRichContent={page.acfRichContent}
          />
        )}
        <BodyContentRenderer acfBodyContent={page.acfBodyContent} />
        <RichContentRenderer acfRichContent={page.acfRichContent} />
        <Box mt={20}>
          <EnquiryForm location={props.location} />
        </Box>
      </main>
      <Footer />
    </Fragment>
  )
}

export default Page

export const pageQuery = graphql`
  query ($id: ID!) {
    wpgraphql {
      page: newsAndEvent(id: $id) {
        title
        uri
        databaseId

        # MASTERQUERY START

        # -------------------------------
        # Header
        # -------------------------------

        acfPageHeader {
          layout
          title
          introduction
          seoTitle
          seoDescription
          seoImage {
            sourceRemote
            sourceLocal {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200, height: 630)
              }
            }
          }
          image {
            altText
            caption
            sourceRemote
            sourceLocal {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          buttonLink {
            title
            url
          }
          buttonColour
        }

        # -------------------------------
        # Breadcrumbs
        # -------------------------------

        appBreadcrumb {
          title
          link
        }

        # -------------------------------
        # Table of contents
        # -------------------------------

        acfTableOfContents {
          tableOfContents
        }

        # -------------------------------
        # Body Content
        # -------------------------------

        acfBodyContent {
          bodyContent {
            __typename
            ... on WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_Wysiwyg {
              contentHeading
              content
            }
            ... on WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_Accordion {
              display
              contentHeading
              accordion {
                heading
                content: contentWysiwyg
                status
              }
            }
            ... on WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_LargeImageBlock {
              image {
                altText
                sourceRemote
                sourceLocal {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 1200
                      height: 680
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
            ... on WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_SmallImageBlock {
              contentHeading
              image {
                altText
                sourceRemote
                sourceLocal {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 1200
                      height: 680
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
            ... on WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_VideoBlock {
              contentHeading
              videoUrl
            }
          }
        }

        # -------------------------------
        # Rich Content / Section Modules
        # -------------------------------

        acfRichContent {
          richContent {
            __typename
            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_BoldStatement {
              sectionHeading
              statement
              link: pageLink {
                title
                url
              }
            }
            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_KeyStats {
              sectionHeading
              themeColor
              stats {
                title
                body
                sourceUrl
                sourceText
              }
            }
            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_KeyStatsFeature {
              sectionHeading
              themeColor
              stats {
                title
                type
                size
                body
                sourceUrl
                sourceText
              }
            }
            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_KeyStatsList {
              sectionHeading
              themeColor
              list {
                heading
                stats {
                  title
                  body
                }
              }
              sourceText
              sourceUrl
            }
            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_BannerFullWidth {
              sectionHeading
              title
              introduction
              link: pageLink {
                title
                url
              }
              themeColor
              image {
                altText
                sourceRemote
                sourceLocal {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 900
                      height: 600
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_BannerFullWidthGroup {
              image {
                altText
                sourceRemote
                sourceLocal {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 900
                      height: 600
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
              banners {
                themeColor
                title
                introduction
                link: pageLink {
                  title
                  url
                }
              }
            }
            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_RelatedResources {
              sectionHeading
              resources {
                ... on WPGraphQL_Resource {
                  uri
                  title
                  link
                  acfPageHeader {
                    title
                    introduction
                  }
                }
              }
            }

            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_RelatedCaseStudies {
              sectionHeading
              caseStudies {
                image {
                  sourceRemote
                  sourceLocal {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        width: 790
                        height: 448
                        transformOptions: { cropFocus: CENTER }
                      )
                    }
                  }
                }
                title
                introduction
                description
                link {
                  title
                  url
                }
              }
            }

            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_Downloads {
              title
              introduction
              downloads {
                download {
                  title
                  fileSizeFormatted
                  fileTypeFormatted
                  sourceRemote
                  sourceLocal {
                    publicURL
                  }
                }
              }
            }

            ... on WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_GlobalModule {
              globalModule
            }
          }
        }

        # MASTERQUERY FINISH
      }
    }
  }
`
